import { Fragment, useEffect } from "react";

import { useSessionId } from "@plansoft-configuration-renderer/framework";

/**
 * Demo on how to access internal state
 * @returns
 */
export function ConfigurationStateSpy(): JSX.Element {
  const sessionId = useSessionId();

  // log session id when it changes
  useEffect(() => {
    console.log("Configuration session id is", sessionId);
  }, [sessionId]);

  return <Fragment />;
}
