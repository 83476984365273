import { Alert, Box, Grid, MenuItem, Select } from "@mui/material";
import {
  CommonErrorBoundary,
  DateLocalizationProvider,
  LocalizationProvider,
  OAuthManager,
} from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { MinimalAppContext } from "./MinimalAppContext";
import { MinimalRenderer } from "./MinimalRenderer";

const cfgObjectId = window.location.hash.split("/")[1];
export const language = import.meta.env.VITE_APP_LANGUAGE || navigator.language;

const cfgObjects = ["GW_CFG", "BS_CFG"];

export function MinimalApp(): JSX.Element {
  return (
    <MinimalAppContext>
      <LocalizationProvider
        configurationLocale={language}
        uiLocale={language}
        configuratorBasePath={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
      >
        <DateLocalizationProvider localeCode={language}>
          <GatewayProvider>
            <CommonErrorBoundary>
              {cfgObjectId ? (
                <>
                  <MinimalRenderer
                    baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
                    cfgObjectId={cfgObjectId}
                    language={language}
                  />
                </>
              ) : import.meta.env.DEV ? (
                <MinimalRenderer
                  baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
                  cfgObjectId={import.meta.env.VITE_APP_CFG_OBJECT_ID}
                  language={import.meta.env.VITE_APP_LANGUAGE}
                />
              ) : (
                <Box>
                  <Alert severity="error">
                    <Box marginBottom={"0.75rem"}>
                      No cfgObjectId set. Please set an cfgObject or select the
                      cfgObject you were looking for. When including this
                      application in an iFrame, check the url of the iFrame for
                      the correct cfgObjectId.
                    </Box>
                    <Select
                      defaultValue=""
                      sx={{ minWidth: "200px" }}
                      onChange={(event) => {
                        window.location.href = "/#/" + event.target.value;
                        window.location.reload();
                      }}
                    >
                      {cfgObjects.map((cfgObject) => {
                        return (
                          <MenuItem value={cfgObject} key={cfgObject}>
                            {cfgObject}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Alert>
                </Box>
              )}
            </CommonErrorBoundary>
            <OAuthManager
              basePath={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
              isLocalDevelopment={import.meta.env.DEV}
            />
            <Grid item>
              <Box position="sticky" top={0}>
                <GatewayDest name="navigation" />
              </Box>
            </Grid>
          </GatewayProvider>
        </DateLocalizationProvider>
      </LocalizationProvider>
    </MinimalAppContext>
  );
}
