import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import Save from "@mui/icons-material/Save";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";

import ResetIcon from "@/assets/svgs/reset.svg?react";

import LoadConfigurationDialog from "./LoadConfigurationDialog/LoadConfigurationDialog";

interface ConfigurationHeaderProps {
  onTypeCodeSubmit: (typecode: string) => void;
}

export default function ConfigurationHeader({
  onTypeCodeSubmit,
}: ConfigurationHeaderProps): ReactNode {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { saveConfiguration, resetCurrentCfgObject } = useCsConfigurationApi();

  const [typeCodeValue, setTypeCodeValue] = useState("");

  const handleTypecodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setTypeCodeValue(event.target.value);
  };

  // TODO: [KS] implement the actual functionality for the AddToCart button
  const handleAddToCartClick = (): void => {
    console.debug("AddToCart button clicked...");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: "1px solid",
        borderColor: palette.grey[500],
        width: "100%",
        padding: "1rem 0",
        zIndex: 10000,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <Typography
          variant={"h5"}
          sx={{
            color: palette.primary.main,
            alignSelf: "center",
            fontWeight: "bold",
          }}
        >
          {t("title")}
        </Typography>
        <IconButton onClick={resetCurrentCfgObject}>
          <SvgIcon component={ResetIcon} inheritViewBox />
        </IconButton>
      </Box>
      <FormGroup sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
        <Typography sx={{ alignSelf: "center" }}>{t("type_key")}</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size={"extraSmall"}
          value={typeCodeValue}
          onChange={handleTypecodeChange}
        />
        <Box>
          <Button
            variant="outlined"
            onClick={() => onTypeCodeSubmit(typeCodeValue)}
          >
            {t("start")}
          </Button>
        </Box>
      </FormGroup>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LoadConfigurationDialog />
        <Button
          sx={{ paddingRight: 0 }}
          size="large"
          startIcon={
            <Tooltip title={t("save")} placement="top" arrow>
              <Save />
            </Tooltip>
          }
          onClick={saveConfiguration}
          disabled
          variant="text"
        />

        <Button
          sx={{ paddingRight: 0 }}
          size="large"
          startIcon={
            <Tooltip title={t("add_to_cart")} placement="top" arrow>
              <ShoppingCart />
            </Tooltip>
          }
          onClick={handleAddToCartClick}
          variant="text"
        >
          <Typography sx={{ paddingLeft: "0.5rem" }}>
            {t("add_to_cart")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
