import { useCallback, useEffect, useMemo, useState } from "react";

import { Viewer3DType } from "@plansoft-configuration-renderer/components";

declare global {
  interface Window {
    CAD_CLICK_VIEWERS: Record<string, Viewer3DType>;
  }
}

export const useCADViewer3D = (): Viewer3DType | null => {
  const [appId, setAppId] = useState<string>("");

  const handleMessageEvent = useCallback((event: MessageEvent): void => {
    if (event.data?.messageType?.startsWith("viewer3D.")) {
      const appId = event.data.messageData.appId;
      setAppId(appId);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [handleMessageEvent]);

  const viewer = useMemo(() => {
    return appId ? window.CAD_CLICK_VIEWERS[appId] : null;
  }, [appId]);

  return viewer;
};
