import { ReactNode } from "react";

import { useCADViewer3D } from "@/hooks/useCADViewer3D";

export default function CustomCadClickViewer3DController(): ReactNode {
  const viewer = useCADViewer3D();

  console.debug("current CadClickViewer3D: ", viewer);

  return <></>;
}
